/*!
 * Figtree by Erik Kennedy - https://github.com/erikdkennedy/figtree
 * License - https://scripts.sil.org/OFL SIL Open Font License (OFL) 1.1
 */
  @font-face {
    font-family: "Figtree";
    font-display: block;
    font-weight: 300;
    src: url("/assets/fonts/figtree/figtree-light.ttf") format("truetype"); }
  @font-face {
    font-family: "Figtree";
    font-display: block;
    font-weight: 400;
    src: url("/assets/fonts/figtree/figtree-regular.ttf") format("truetype"); }
  @font-face {
    font-family: "Figtree";
    font-display: block;
    font-weight: 500;
    src: url("/assets/fonts/figtree/figtree-medium.ttf") format("truetype"); }
  @font-face {
    font-family: "Figtree";
    font-display: block;
    font-weight: 600;
    src: url("/assets/fonts/figtree/figtree-semibold.ttf") format("truetype"); }
  @font-face {
    font-family: "Figtree";
    font-display: block;
    font-weight: 700;
    src: url("/assets/fonts/figtree/figtree-bold.ttf") format("truetype"); }
  @font-face {
    font-family: "Figtree";
    font-display: block;
    font-weight: 800;
    src: url("/assets/fonts/figtree/figtree-extrabold.ttf") format("truetype"); }
  @font-face {
    font-family: "Figtree";
    font-display: block;
    font-weight: 900;
    src: url("/assets/fonts/figtree/figtree-black.ttf") format("truetype"); }
