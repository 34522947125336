
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


@import 'colors';

$youturn-primary-palette: (
  50: #e0e5f1,
  100: #b3bedc,
  200: #8093c5,
  300: #4d67ad,
  400: #26479c,
  500: #00268a,
  600: #002282,
  700: #001c77,
  800: #00176d,
  900: #00268a,
  A100: #8b93ff,
  A200: #5864ff,
  A400: #2534ff,
  A700: #2534ff,
  contrast: (
    50: $surface-neutral-900,
    100: $surface-neutral-900,
    200: $surface-neutral-900,
    300: $content-dark-surface,
    400: $content-dark-surface,
    500: $content-dark-surface,
    600: $content-dark-surface,
    700: $content-dark-surface,
    800: $content-dark-surface,
    900: $content-dark-surface,
    A100: $surface-neutral-900,
    A200: $content-dark-surface,
    A400: $content-dark-surface,
    A700: $content-dark-surface,
  )
);

$youturn-angular-primary: mat.define-palette($youturn-primary-palette);
$youturn-angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$youturn-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$youturn-angular-theme: mat.define-light-theme((
  color: (
    primary: $youturn-angular-primary,
    accent: $youturn-angular-accent,
    warn: $youturn-angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($youturn-angular-theme);

/* You can add global styles to this file, and also import other style files */
@import 'font-figtree';
@import 'font-font-awesome';
@import 'colors';
@import "commons";

html, body {
  height: 100%;
  /* We have to override Material(gag) styling, so using bad practices with !important */
  font-family: Figtree, sans-serif !important;
}
body { margin: 0; font-family: Figtree, sans-serif; }

@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';
@import "font-figtree";

/*
   ADD MATERIAL COMPONENT CLASSES HERE
   Material annoyingly adds font-family to all component base classes
   so add them here to be overwritten
  */
.mat-table,
.mat-paginator,
.mat-tab-label,
.mat-menu,
.mat-menu-item,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-form-field,
.mat-datepicker,
.mat-datepicker-toggle,
.mat-expansion-panel-header,
.mat-expansion-panel-content,
.mat-list-option,
.mat-dialog-title,
.mat-select,
.mat-slide-toggle,
.mat-slide-toggle-content,
.mat-typography,
.mat-option-text {
  font-family: Figtree, sans-serif !important;
}

.mat-typography {
  h1, h2, h3, h4, h5, h6, p, span, a, label, input, button {
    font-family: Figtree, sans-serif !important;
  }

  h1 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 48px;
    white-space: nowrap;
  }
}

.mat-select-panel .mat-option {
  padding: 0 24px;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: #fff;
  color: $surface-primary;
}

.mat-select-panel-wrap .mat-select-panel {
  border-radius: 12px;
  padding: 8px 0;
}

.youturn-input {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border-color: $surface-neutral-200;
    }

    .mat-form-field-flex {
      padding: 0 size(3)
    }
  }

  .mat-form-field-label {
    color: $content-light-surface-500 !important;
  }
}

.mat-flat-button.primary {
  padding: 0 size(3);
  background-color: $surface-primary-900;
  color: #FFFFFF;
}

a {
  color: $surface-primary;
  text-decoration: none;
}
