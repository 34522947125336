@import 'assets/stylesheets/material';

html {
  /* We have to override Material(gag) styling, so using bad practices with !important */
  font-family: Figtree, serif !important;
}

/*
app-root {
  display: block !important;
}*/

.mat-stepper-horizontal {
  font-family: Figtree, serif !important;
}

.feature-state-todo {
  opacity: 0.2;
  user-select: none;
  pointer-events: none;
}

.form__field {
  margin-bottom: 16px;
}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  &.custom-btn {
    min-height: 56px;
    min-width: 80px;
    border-radius: 8px;

    border: 1px solid;
    border-color: $surface-neutral-200;

    &.small-custom-btn {
      min-height: initial;
    }
  }
}

.step-counter {
  .mat-horizontal-stepper-header-container {
    display: flex;
    justify-content: center;
  }

  .mat-horizontal-stepper-wrapper {
    align-items: center;
  }

  .mat-step-icon-content {
    display: none;
  }

  .mat-step-header {
    .mat-step-icon {
      background-color: #f0f4ff;
      border: 0px none;
      height: 4px;
      width: 95px;
      border-radius: 8px;
    }

    .mat-step-optional {
      display: none;
    }
  }

  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: #2461ff;

    &.home-counter {
      background-color: #f0f4ff;
    }
  }

  &.home-counter {
    .mat-step-header .mat-step-icon-selected {
      background-color: #f0f4ff;
    }
  }

  .mat-horizontal-stepper-header {
    padding: 0 10px;
  }
}

.welcome-text {
  text-align: center;
}

.example-container {
  .mat-drawer-content {
    height: 100vh;
  }
}

.page-container {
  padding: 32px;
}

.mat-ink-bar {
  background-color: $surface-primary !important;
  border-radius: 3px;
  height: 3px !important;;
}

.mat-tab-label {
  .mat-tab-label-content {
    color: $surface-neutral-900;
    font-family: "Figtree-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    white-space: nowrap;
  }
}

.mat-tab-header {
  border: none;
}

.mat-drawer-backdrop, .mat-drawer-backdrop.mat-drawer-shown, .mat-drawer-transition .mat-drawer-backdrop {
  position: fixed;
}

.mat-drawer-container {
  background-color: transparent;
}
