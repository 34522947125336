$surface-base-50: #F7F7F8;
$surface-base-950: #232429;
$surface-neutral-10: #FCFCFD;
$surface-neutral-200: #CACDD3;
$surface-neutral-400: #9EA3AD;
$surface-neutral-500: #888D9A;
$surface-neutral-800: #4D505B;
$surface-neutral-900: #363840;
$surface-primary-50: #F5F8FF;
$surface-primary-100: #F0F4FF;
$surface-primary: #2461FF; // 500
$surface-primary-900: #00268A;
$surface-tertiary-500: #00C4E5;
$surface-tertiary-purple: #5E1FC4;

$content-dark-surface: #fff;
$content-light-surface-500: #888D9A;
$content-light-surface-700: #606471;
$content-light-surface-900: #363840;

$error-10: #FAE2E2;
$error-700: #A52C40;
$error-900: #7D2130;
$warning-10: #FDF4E2;
$warning-500: #F2AA27;
$warning-950: #785007;
$success-10: #C8FEE6;
$success-500: #299365;

